.watch-icon-loading {
    color: #0088a9;
    cursor: progress;
}

.watch-icon {
    color: #0088a9;
}

.watch-icon-error {
    color: red
}

.watch-icon-subscribed {
    color: green;
}

.watch-icon-unsubscribed {
    color: darkgreen;
}

.watch-icon-clickable:hover {
    color: black;
    cursor: pointer
}

.watch-icon-clickable:active {
    color: black;
}

.rcw-main a {
    cursor: pointer;
}
